/* eslint-disable */

import '../vendor/normalize.min.css';
import '../vendor/bootstrap.min.css';
import '../vendor/bundle.css';

import '../styles/fonts.scss';
import '../styles/typography.scss';
import '../styles/main.scss';
import '../styles/components/header/index.scss';
import '../styles/components/start-button/index.scss';
import '../styles/components/checkbox/index.scss';
import '../styles/components/timer/index.scss';
import '../styles/components/radio.scss';
import '../styles/components/question-group/index.scss';
import '../styles/components/info-cards.scss';
import '../styles/components/info-link/index.scss';
import '../styles/components/button/index.scss';
import '../styles/components/slider/index.scss';
import '../styles/components/footer.scss';
import '../styles/components/popup/index.scss';

import '../vendor/jquery.js';
import Siema from 'siema';

$(function() {
  const $questions = $('[data-question]');
  const questionsCount = $questions.length;
  const $answers = $('[data-answer-block]');
  const $startBtn = $('[data-start-button]');
  const $timer = $('[data-timer]');
  const $timerText = $('[data-timer-text]');
  const $timerCounter = $('[data-timer-count]');
  const $timerMin = $('[data-timer-min]');
  const $popup = $('[data-popup]');
  const $btnContinue = $('[data-btn-continue]');
  const $btnRestart = $('[data-btn-restart]');
  let timerMin = Number($timerMin.text().trim());
  const $timerSec = $('[data-timer-sec]');
  let timerSec = Number($timerSec.text().trim());
  let timerIsRunning = false;
  const answers = [];
  let isAllAnswered = false;

  for (let i = 0; i < questionsCount; i++) { 
    answers.push({ id: i+1, value: undefined })
  }

  $('[data-info-add-button]').on('click', function(){
    $('[data-info-add-button]').toggleClass('_is-open');
    $('[data-info-add-text]').toggleClass('_is-open');
  });

  $startBtn.on('click', function(){
    setTimer(true)
  });

  const timerFunc = function() {
    if (timerMin === 0 && timerSec === 0) {
      clearInterval(timerFunc);
      console.log('время закончилось');
      $timerText.text('Время закончилось');
      $timerCounter.removeClass('_running').removeClass('_atention');
      $popup.addClass('_visible')
      setTimer(false);
    } else {
      if (timerMin === 0 && timerSec < 41) {
        console.log('осталось 40 секунд');
        $timerText.text('у вас осталось');
        $timerCounter.removeClass('_hidden').removeClass('_running');
        $timerCounter.addClass('_atention');
      }
      timerMin = timerSec === 0 ? timerMin - 1 : timerMin;
      timerSec = timerSec === 0 ? 60 : timerSec;
      timerSec = timerSec - 1;
      $timerMin.text(timerMin)
      $timerSec.text(timerSec < 10 ? `0${timerSec}` : timerSec)
    }
  };
  let tmr = setInterval(timerFunc, 1000, [timerMin, timerSec]);
  clearInterval(tmr);

  function setTimer(b) {
    console.log('timerIsRunning', b);
    timerIsRunning = b;
    clearInterval(tmr);
    
    if (timerIsRunning) {
      console.log('время началось');
      $timerText.text('Успейте за');
      $timerCounter.addClass('_running');
      tmr = setInterval(timerFunc, 1000, [timerMin, timerSec]);
      $startBtn.addClass('_active');
    } else {
      clearInterval(tmr);
      $startBtn.removeClass('_active');
      // $timer.addClass('_hidden');
    }
  }

  // $btnContinue
  $btnContinue.click(function(){
    $popup.removeClass('_visible');
    $questions.each(function() {
      $(this)[0].reset()
    });
    $('[data-answer-block]').removeClass('_hidden');
    $('[data-question-block]').addClass('_hidden')
    timerMin = 5;
    timerSec = 0;
    setTimer(false);
  });

  $btnRestart.click(function(){
    $popup.removeClass('_visible');
    timerMin = 5;
    timerSec = 0;
    setTimer(true);
  });

  $questions.each(function() {
    const question = this;
    const id = $(question).attr('data-question');
    const answer = $(question).attr('data-answer');
    const $answerBlock = $(`[data-answer-block=${id}]`);
    const $questionBlock = $(question).find(`[data-question-block]`);
    const hasCarousel = $(question).find('[data-carousel]').length > 0;

    $(question).submit(function(event) {
      event.preventDefault();
    });

    $(question).change(function(e) {
      answers.filter(v => v.id == id)[0].value = e.target.value;

      const values = $(this).serialize();
      if ( values === answer ) {
        console.log(`Вопрос ${id}: Пользователь ответил правильно`);
      } else {
        console.log(`Вопрос ${id}: Пользователь ответил НЕ правильно`);
      }

      isAllAnswered = answers.filter(v => v.value !== undefined).length === questionsCount;

      if (!timerIsRunning) {
        setTimer(true);
      }

      if (id != 1 || values.length > 10) {

        // let tm = 2000;
        const tmr = setTimeout(function() {
          $questionBlock.addClass('_hidden');
          $answerBlock.removeClass('_hidden');
          if (hasCarousel) {
            const carousel = new Siema({
              selector: '[data-carousel]',
              perPage: {
                0: 1,
                768: 2,
                1170: 3,
                1400: 4,
                5000: 6,
              },
              loop: true,
            });
          
            $('[data-carousel-prev]').click(function(){
              carousel.prev();
            });
          
            $('[data-carousel-next]').click(function(){
              carousel.next();
            });
          }
        }, 1000);

        if (isAllAnswered) {
          setTimer(false);
        }
      }

    })
  })

  const $scrollToBtns = $('[data-scroll-to]');
  $scrollToBtns.each(function(){
    $(this).on('click', function(){
      const id = $(this).data('scroll-to');
      const target = $(id);
      const targetNode = target[0];
      let targetOffsetTop = target.offset().top;
      const windowHeight = $(window).outerHeight();

      if (id === '#footnote' && !target.hasClass('_is-open')) {
        target.trigger('click');
        setTimeout(() => {
          targetOffsetTop = target.offset().top;
          $('html, body').stop().animate({
            scrollTop: targetOffsetTop - 75
          }, 600);
        }, 600);
      } else {
        $('html, body').stop().animate({
          scrollTop: targetOffsetTop - 75
        }, 600);
      }

    })
  });

});